// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Number from "../../../libs/Number.res.js";
import * as TextField from "../TextField/TextField.res.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MoneyFieldScss from "./MoneyField.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = MoneyFieldScss;

function input() {
  return /^\d+\.?\d{0,2}$/;
}

var Re = {
  input: input
};

function format(x) {
  return $$Number.formatWithFractionDigits(x, 2);
}

function MoneyField(props) {
  var onChange = props.onChange;
  var value = props.value;
  var initialState = React.useMemo((function () {
          return {
                  input: Belt_Option.getWithDefault(Belt_Option.map(value, format), "")
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          var value = action._0;
          if (value === "") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      input: value
                    },
                    _1: (function (param) {
                        onChange(undefined);
                      })
                  };
          } else if (input().test(value)) {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      input: value
                    },
                    _1: (function (param) {
                        onChange(Belt_Option.getExn(Belt_Float.fromString(value)));
                      })
                  };
          } else {
            return "NoUpdate";
          }
        }));
  var dispatch = match[1];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(TextField.make, {
                    id: props.id,
                    value: match[0].input,
                    status: props.status,
                    className: css.input,
                    onChange: (function ($$event) {
                        dispatch({
                              TAG: "Update",
                              _0: $$event.target.value
                            });
                      })
                  }),
              className: css.wrapper
            });
}

var make = MoneyField;

export {
  css ,
  Re ,
  format ,
  make ,
}
/* css Not a pure module */
