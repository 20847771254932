// Generated by ReScript, PLEASE EDIT WITH CARE


function fromJs(js) {
  return {
          name: js.name,
          subcategories: js.subcategories
        };
}

export {
  fromJs ,
}
/* No side effect */
