// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Router from "../../../libs/Router.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_Product from "../../../routes/common/Routes_Product.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorHeader(props) {
  var active = props.active;
  var product = props.product;
  var tmp;
  switch (active) {
    case "GeneralTab" :
        tmp = css.headerMenuItemLabelActive;
        break;
    case "LocationTab" :
    case "AssetsTab" :
        tmp = "";
        break;
    
  }
  var tmp$1;
  switch (active) {
    case "LocationTab" :
        tmp$1 = css.headerMenuItemLabelActive;
        break;
    case "GeneralTab" :
    case "AssetsTab" :
        tmp$1 = "";
        break;
    
  }
  var tmp$2;
  switch (active) {
    case "GeneralTab" :
    case "LocationTab" :
        tmp$2 = "";
        break;
    case "AssetsTab" :
        tmp$2 = css.headerMenuItemLabelActive;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(Container.make, {
                      className: css.headerInner,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.headerTitle,
                              children: "Add Product"
                            }),
                        JsxRuntime.jsx("div", {
                              className: css.headerMenuPlaceholder
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(Container.make, {
                            className: css.headerProgressLineInner,
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: product !== undefined ? JsxRuntime.jsx(Router.Link.make, {
                                                  path: Routes_Product.Dashboard.editGeneral(product.id),
                                                  children: "1. General & Price"
                                                }) : "1. General & Price",
                                          className: Cx.cx([
                                                css.headerMenuItemLabel,
                                                product !== undefined ? css.headerMenuItemLabelLink : "",
                                                tmp
                                              ])
                                        }),
                                    className: css.headerMenuItem
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: product !== undefined ? JsxRuntime.jsx(Router.Link.make, {
                                                  path: Routes_Product.Dashboard.editLocation(product.id),
                                                  children: "2. Location"
                                                }) : "2. Location",
                                          className: Cx.cx([
                                                css.headerMenuItemLabel,
                                                product !== undefined ? css.headerMenuItemLabelLink : "",
                                                tmp$1
                                              ])
                                        }),
                                    className: css.headerMenuItem
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: product !== undefined && Belt_Option.isSome(product.location) ? JsxRuntime.jsx(Router.Link.make, {
                                                  path: Routes_Product.Dashboard.editAssets(product.id),
                                                  children: "3. Images & Videos"
                                                }) : "3. Images & Videos",
                                          className: Cx.cx([
                                                css.headerMenuItemLabel,
                                                product !== undefined && product.location !== undefined ? css.headerMenuItemLabelLink : "",
                                                tmp$2
                                              ])
                                        }),
                                    className: css.headerMenuItem
                                  })
                            ]
                          }),
                      className: css.headerProgressLine
                    })
              ],
              className: css.header
            });
}

var make = DashboardProductEditorHeader;

export {
  css ,
  make ,
}
/* css Not a pure module */
