// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorActionBar(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Container.make, {
                    className: css.actionBarInner,
                    children: props.children
                  }),
              className: css.actionBar
            });
}

function DashboardProductEditorActionBar$ContextControls(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.actionBarLeftSideControlsContainer
            });
}

var ContextControls = {
  make: DashboardProductEditorActionBar$ContextControls
};

function DashboardProductEditorActionBar$Note(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.actionBarNote
            });
}

var Note = {
  make: DashboardProductEditorActionBar$Note
};

function DashboardProductEditorActionBar$Error(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.actionBarError
            });
}

var $$Error = {
  make: DashboardProductEditorActionBar$Error
};

var make = DashboardProductEditorActionBar;

export {
  css ,
  make ,
  ContextControls ,
  Note ,
  $$Error ,
}
/* css Not a pure module */
