// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";

function toLabel(x) {
  switch (x) {
    case "month" :
        return "1 month";
    case "three_years" :
        return "3 years";
    case "two_years" :
        return "2 years";
    case "year" :
        return "1 year";
    default:
      return PervasivesU.failwith("Unknown product term");
  }
}

export {
  toLabel ,
}
/* No side effect */
