// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorLayout(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.layout
            });
}

var make = DashboardProductEditorLayout;

export {
  css ,
  make ,
}
/* css Not a pure module */
