// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as MoneyField from "../../../styleguide/forms/MoneyField/MoneyField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTerm from "../../../models/ProductTerm.res.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as FieldWithCounter from "../../../styleguide/forms/FieldWithCounter/FieldWithCounter.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";
import * as DashboardProductFeatureEditor from "./DashboardProductFeatureEditor.res.js";

var css = DashboardProductEditorScss;

function DashboardProductEditorGeneralTab(props) {
  var removeFeature = props.removeFeature;
  var updateFeature = props.updateFeature;
  var addFeature = props.addFeature;
  var update = props.update;
  var results = props.results;
  var providers = props.providers;
  var categories = props.categories;
  var input = props.input;
  var tmp;
  if (providers.NAME === "Many") {
    var tmp$1;
    if (results !== undefined) {
      var message = results.category;
      tmp$1 = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
              children: message._0
            });
    } else {
      tmp$1 = null;
    }
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Label.make, {
                  forId: "product-form--provider",
                  status: Belt_Option.map(results, (function (x) {
                          return x.providerId;
                        })),
                  children: "Provider"
                }),
            JsxRuntime.jsxs(Select.make, {
                  id: "product-form--provider",
                  value: Belt_Option.getWithDefault(Belt_Option.map(input.providerId, (function (id) {
                              return ID.toString(id);
                            })), ""),
                  status: Belt_Option.map(results, (function (x) {
                          if (x.providerId.TAG === "Ok") {
                            return "Valid";
                          } else {
                            return "Error";
                          }
                        })),
                  onChange: (function ($$event) {
                      var value = $$event.target.value;
                      if (Belt_Option.getWithDefault(Belt_Option.map(input.providerId, (function (id) {
                                    return ID.toString(id) !== value;
                                  })), true)) {
                        return update({
                                    name: input.name,
                                    description: input.description,
                                    providerId: Caml_option.some(ID.fromString(value)),
                                    category: input.category,
                                    subcategory: input.subcategory,
                                    startDate: input.startDate,
                                    endDate: input.endDate,
                                    isExclusiveOffer: input.isExclusiveOffer,
                                    pricePerMonth: input.pricePerMonth,
                                    setupFee: input.setupFee,
                                    term: input.term,
                                    powerCircuit: input.powerCircuit,
                                    bandwidth: input.bandwidth,
                                    ipAllocation: input.ipAllocation,
                                    totalPowerKw: input.totalPowerKw,
                                    features: input.features
                                  });
                      }
                      
                    }),
                  children: [
                    JsxRuntime.jsx(Select.DefaultOption.make, {
                          children: "Select Provider..."
                        }),
                    Belt_Array.map(providers.VAL, (function (provider) {
                            return JsxRuntime.jsx(Select.$$Option.make, {
                                        value: ID.toString(provider.id),
                                        children: provider.name
                                      }, provider.name);
                          }))
                  ]
                }),
            tmp$1
          ],
          className: css.field
        });
  } else {
    tmp = null;
  }
  var tmp$2;
  if (results !== undefined) {
    var message$1 = results.category;
    tmp$2 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            children: message$1._0
          });
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (results !== undefined) {
    var message$2 = results.subcategory;
    tmp$3 = message$2.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            children: message$2._0
          });
  } else {
    tmp$3 = null;
  }
  var tmp$4;
  if (results !== undefined) {
    var message$3 = results.totalPowerKw;
    tmp$4 = message$3.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            children: message$3._0
          });
  } else {
    tmp$4 = null;
  }
  var tmp$5;
  if (results !== undefined) {
    var message$4 = results.name;
    tmp$5 = message$4.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$4._0
          });
  } else {
    tmp$5 = null;
  }
  var tmp$6;
  if (results !== undefined) {
    var message$5 = results.description;
    tmp$6 = message$5.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$5._0
          });
  } else {
    tmp$6 = null;
  }
  var tmp$7;
  if (results !== undefined) {
    var message$6 = results.powerCircuit;
    tmp$7 = message$6.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$6._0
          });
  } else {
    tmp$7 = null;
  }
  var tmp$8;
  if (results !== undefined) {
    var message$7 = results.bandwidth;
    tmp$8 = message$7.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$7._0
          });
  } else {
    tmp$8 = null;
  }
  var tmp$9;
  if (results !== undefined) {
    var message$8 = results.ipAllocation;
    tmp$9 = message$8.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$8._0
          });
  } else {
    tmp$9 = null;
  }
  var tmp$10;
  if (results !== undefined) {
    var message$9 = results.pricePerMonth;
    tmp$10 = message$9.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$9._0
          });
  } else {
    tmp$10 = null;
  }
  var tmp$11;
  if (results !== undefined) {
    var message$10 = results.term;
    tmp$11 = message$10.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$10._0
          });
  } else {
    tmp$11 = null;
  }
  var tmp$12;
  if (results !== undefined) {
    var message$11 = results.setupFee;
    tmp$12 = message$11.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$11._0
          });
  } else {
    tmp$12 = null;
  }
  var tmp$13;
  if (results !== undefined) {
    var message$12 = results.startDate;
    tmp$13 = message$12.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$12._0
          });
  } else {
    tmp$13 = null;
  }
  var tmp$14;
  if (results !== undefined) {
    var message$13 = results.endDate;
    tmp$14 = message$13.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$13._0
          });
  } else {
    tmp$14 = null;
  }
  return JsxRuntime.jsx(Container.make, {
              children: JsxRuntime.jsxs(Form.make, {
                    className: css.form,
                    onSubmit: props.submit,
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Describe Product"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              tmp,
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--category",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.category;
                                                  })),
                                            children: "Category"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--category",
                                            value: Belt_Option.getWithDefault(input.category, ""),
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.category.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                var value = $$event.target.value;
                                                if (Caml_obj.notequal(value, input.category)) {
                                                  return update({
                                                              name: input.name,
                                                              description: input.description,
                                                              providerId: input.providerId,
                                                              category: value,
                                                              subcategory: undefined,
                                                              startDate: input.startDate,
                                                              endDate: input.endDate,
                                                              isExclusiveOffer: input.isExclusiveOffer,
                                                              pricePerMonth: input.pricePerMonth,
                                                              setupFee: input.setupFee,
                                                              term: input.term,
                                                              powerCircuit: input.powerCircuit,
                                                              bandwidth: input.bandwidth,
                                                              ipAllocation: input.ipAllocation,
                                                              totalPowerKw: input.totalPowerKw,
                                                              features: input.features
                                                            });
                                                }
                                                
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select Category..."
                                                  }),
                                              Belt_Array.map(categories, (function (category) {
                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: category.name,
                                                                  children: $$String.capitalize_ascii(category.name)
                                                                }, category.name);
                                                    }))
                                            ]
                                          }),
                                      tmp$2
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--subcategory",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.subcategory;
                                                  })),
                                            children: "Space"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--subcategory",
                                            value: Belt_Option.getWithDefault(input.subcategory, ""),
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.subcategory.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: $$event.target.value,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select Product Type..."
                                                  }),
                                              Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(input.category, (function (category) {
                                                              return Js_array.find((function (category$p) {
                                                                            return category$p.name === category;
                                                                          }), categories);
                                                            })), (function (category) {
                                                          return Belt_Array.map(category.subcategories, (function (subcategory) {
                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                    value: subcategory,
                                                                                    children: $$String.capitalize_ascii(subcategory)
                                                                                  }, subcategory);
                                                                      }));
                                                        })), null)
                                            ]
                                          }),
                                      tmp$3
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--totalPowerKw",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.totalPowerKw;
                                                  })),
                                            children: "Total Product Power"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(TextField.make, {
                                                  id: "product-form--totalPowerKw",
                                                  value: String(input.totalPowerKw),
                                                  status: Belt_Option.map(results, (function (x) {
                                                          if (x.totalPowerKw.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      var x = $$event.target.value;
                                                      var tmp;
                                                      if (x === "") {
                                                        tmp = 0;
                                                      } else {
                                                        var x$1 = Belt_Int.fromString(x);
                                                        tmp = x$1 !== undefined ? x$1 : 0;
                                                      }
                                                      update({
                                                            name: input.name,
                                                            description: input.description,
                                                            providerId: input.providerId,
                                                            category: input.category,
                                                            subcategory: input.subcategory,
                                                            startDate: input.startDate,
                                                            endDate: input.endDate,
                                                            isExclusiveOffer: input.isExclusiveOffer,
                                                            pricePerMonth: input.pricePerMonth,
                                                            setupFee: input.setupFee,
                                                            term: input.term,
                                                            powerCircuit: input.powerCircuit,
                                                            bandwidth: input.bandwidth,
                                                            ipAllocation: input.ipAllocation,
                                                            totalPowerKw: tmp,
                                                            features: input.features
                                                          });
                                                    })
                                                }),
                                            className: css.kwField
                                          }),
                                      tmp$4
                                    ],
                                    className: css.field
                                  })
                            ],
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fieldGroupAuto_md_lg
                                ])
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(FieldWithCounter.make, {
                                          value: input.name,
                                          limit: ColocationProduct.nameMaxLength,
                                          label: JsxRuntime.jsx(Label.make, {
                                                forId: "product-form--name",
                                                status: Belt_Option.map(results, (function (x) {
                                                        return x.name;
                                                      })),
                                                children: "Name"
                                              }),
                                          field: JsxRuntime.jsx(TextField.make, {
                                                id: "product-form--name",
                                                value: input.name,
                                                placeholder: "Ex. Half Rack Promotion",
                                                status: Belt_Option.map(results, (function (x) {
                                                        if (x.name.TAG === "Ok") {
                                                          return "Valid";
                                                        } else {
                                                          return "Error";
                                                        }
                                                      })),
                                                onChange: (function ($$event) {
                                                    update({
                                                          name: $$event.target.value,
                                                          description: input.description,
                                                          providerId: input.providerId,
                                                          category: input.category,
                                                          subcategory: input.subcategory,
                                                          startDate: input.startDate,
                                                          endDate: input.endDate,
                                                          isExclusiveOffer: input.isExclusiveOffer,
                                                          pricePerMonth: input.pricePerMonth,
                                                          setupFee: input.setupFee,
                                                          term: input.term,
                                                          powerCircuit: input.powerCircuit,
                                                          bandwidth: input.bandwidth,
                                                          ipAllocation: input.ipAllocation,
                                                          totalPowerKw: input.totalPowerKw,
                                                          features: input.features
                                                        });
                                                  })
                                              })
                                        }),
                                    tmp$5
                                  ],
                                  className: css.field
                                }),
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fieldGroup1_md_lg
                                ])
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(FieldWithCounter.make, {
                                          value: input.description,
                                          limit: ColocationProduct.descriptionMaxLength,
                                          label: JsxRuntime.jsx(Label.make, {
                                                forId: "product-form--description",
                                                status: Belt_Option.map(results, (function (x) {
                                                        return x.description;
                                                      })),
                                                children: "Description"
                                              }),
                                          field: JsxRuntime.jsx(Textarea.make, {
                                                id: "product-form--description",
                                                value: input.description,
                                                rows: 5,
                                                placeholder: "Ex. We're a growing business and need a private cage in Ashburn, VA to support our company's internal operations.",
                                                status: Belt_Option.map(results, (function (x) {
                                                        if (x.description.TAG === "Ok") {
                                                          return "Valid";
                                                        } else {
                                                          return "Error";
                                                        }
                                                      })),
                                                onChange: (function ($$event) {
                                                    update({
                                                          name: input.name,
                                                          description: $$event.target.value,
                                                          providerId: input.providerId,
                                                          category: input.category,
                                                          subcategory: input.subcategory,
                                                          startDate: input.startDate,
                                                          endDate: input.endDate,
                                                          isExclusiveOffer: input.isExclusiveOffer,
                                                          pricePerMonth: input.pricePerMonth,
                                                          setupFee: input.setupFee,
                                                          term: input.term,
                                                          powerCircuit: input.powerCircuit,
                                                          bandwidth: input.bandwidth,
                                                          ipAllocation: input.ipAllocation,
                                                          totalPowerKw: input.totalPowerKw,
                                                          features: input.features
                                                        });
                                                  })
                                              })
                                        }),
                                    tmp$6
                                  ],
                                  className: css.field
                                }),
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fieldGroup1_md_lg
                                ])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--powerCircuit",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.powerCircuit;
                                                  })),
                                            children: "Power"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--powerCircuit",
                                            value: input.powerCircuit,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.powerCircuit.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: $$event.target.value,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select Power..."
                                                  }),
                                              Belt_Array.map(props.powerOptions, (function (power) {
                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: power,
                                                                  children: $$String.capitalize_ascii(power)
                                                                }, power);
                                                    }))
                                            ]
                                          }),
                                      tmp$7
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--bandwidth",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.bandwidth;
                                                  })),
                                            children: "Bandwidth"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--bandwidth",
                                            value: input.bandwidth,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.bandwidth.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: $$event.target.value,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select Bandwidth..."
                                                  }),
                                              Belt_Array.map(props.bandwidthOptions, (function (bandwidth) {
                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: bandwidth,
                                                                  children: $$String.capitalize_ascii(bandwidth)
                                                                }, bandwidth);
                                                    }))
                                            ]
                                          }),
                                      tmp$8
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--ipAllocation",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.ipAllocation;
                                                  })),
                                            children: "IP Allocation"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--ipAllocation",
                                            value: input.ipAllocation,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.ipAllocation.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: $$event.target.value,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select IP Allocation..."
                                                  }),
                                              Belt_Array.map(props.ipAllocationOptions, (function (ipAllocation) {
                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: ipAllocation,
                                                                  children: $$String.capitalize_ascii(ipAllocation)
                                                                }, ipAllocation);
                                                    }))
                                            ]
                                          }),
                                      tmp$9
                                    ],
                                    className: css.field
                                  })
                            ],
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fieldGroup3_md_lg
                                ])
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                  id: "product-form--isExclusiveOffer",
                                  size: "LG",
                                  checked: input.isExclusiveOffer,
                                  onChange: (function ($$event) {
                                      update({
                                            name: input.name,
                                            description: input.description,
                                            providerId: input.providerId,
                                            category: input.category,
                                            subcategory: input.subcategory,
                                            startDate: input.startDate,
                                            endDate: input.endDate,
                                            isExclusiveOffer: $$event.target.checked,
                                            pricePerMonth: input.pricePerMonth,
                                            setupFee: input.setupFee,
                                            term: input.term,
                                            powerCircuit: input.powerCircuit,
                                            bandwidth: input.bandwidth,
                                            ipAllocation: input.ipAllocation,
                                            totalPowerKw: input.totalPowerKw,
                                            features: input.features
                                          });
                                    }),
                                  children: "Datacenters.com exclusive offer"
                                }),
                            className: Cx.cx([
                                  css.fieldGroup_md_lg,
                                  css.fieldGroup1_md_lg
                                ])
                          }),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Add Product Features"
                          }),
                      JsxRuntime.jsx("div", {
                            children: "Add up to four features that describe this product.",
                            className: css.note
                          }),
                      Belt_Array.mapWithIndex(input.features, (function (idx, feature) {
                              return JsxRuntime.jsx(DashboardProductFeatureEditor.make, {
                                          i: idx,
                                          input: feature,
                                          results: Belt_Option.flatMap(results, (function (x) {
                                                  return Belt_Array.get(x.features, idx);
                                                })),
                                          update: updateFeature,
                                          remove: removeFeature
                                        }, String(idx));
                            })),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Button.make, {
                                    size: "MD",
                                    color: "Teal",
                                    visuallyDisabled: input.features.length >= ColocationProduct.maxFeatures,
                                    onClick: (function (param) {
                                        addFeature();
                                      }),
                                    children: "Add Feature"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "4 features max",
                                    className: css.note
                                  })
                            ],
                            className: css.featuresButton
                          }),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Set Price"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--pricePerMonth",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.pricePerMonth;
                                                  })),
                                            children: "Price per Month"
                                          }),
                                      JsxRuntime.jsx(MoneyField.make, {
                                            id: "product-form--pricePerMonth",
                                            value: input.pricePerMonth,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.pricePerMonth.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: value,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              })
                                          }),
                                      tmp$10
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--term",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.term;
                                                  })),
                                            children: "Term"
                                          }),
                                      JsxRuntime.jsxs(Select.make, {
                                            id: "product-form--term",
                                            value: input.term,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.term.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: $$event.target.value,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              }),
                                            children: [
                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                    children: "Select Term..."
                                                  }),
                                              Belt_Array.map(props.termOptions, (function (term) {
                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: term,
                                                                  children: ProductTerm.toLabel(term)
                                                                }, term);
                                                    }))
                                            ]
                                          }),
                                      tmp$11
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--setupFee",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.setupFee;
                                                  })),
                                            children: "Setup Fee"
                                          }),
                                      JsxRuntime.jsx(MoneyField.make, {
                                            id: "product-form--setupFee",
                                            value: input.setupFee,
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.setupFee.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: value,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              })
                                          }),
                                      tmp$12
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--price--startDate",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.startDate;
                                                  })),
                                            children: "Start Date"
                                          }),
                                      JsxRuntime.jsx(DatePicker.make, {
                                            id: "product-form--price--startDate",
                                            value: Belt_Option.map(input.startDate, (function (prim) {
                                                    return prim;
                                                  })),
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.startDate.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onDayChange: (function (value, param, param$1) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: Caml_option.some(value),
                                                      endDate: input.endDate,
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              })
                                          }),
                                      tmp$13
                                    ],
                                    className: css.field
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "product-form--price--endDate",
                                            status: Belt_Option.map(results, (function (x) {
                                                    return x.endDate;
                                                  })),
                                            children: "End Date"
                                          }),
                                      JsxRuntime.jsx(DatePicker.make, {
                                            id: "product-form--price--endDate",
                                            value: Belt_Option.map(input.endDate, (function (prim) {
                                                    return prim;
                                                  })),
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.endDate.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onDayChange: (function (value, param, param$1) {
                                                update({
                                                      name: input.name,
                                                      description: input.description,
                                                      providerId: input.providerId,
                                                      category: input.category,
                                                      subcategory: input.subcategory,
                                                      startDate: input.startDate,
                                                      endDate: Caml_option.some(value),
                                                      isExclusiveOffer: input.isExclusiveOffer,
                                                      pricePerMonth: input.pricePerMonth,
                                                      setupFee: input.setupFee,
                                                      term: input.term,
                                                      powerCircuit: input.powerCircuit,
                                                      bandwidth: input.bandwidth,
                                                      ipAllocation: input.ipAllocation,
                                                      totalPowerKw: input.totalPowerKw,
                                                      features: input.features
                                                    });
                                              })
                                          }),
                                      tmp$14
                                    ],
                                    className: css.field
                                  })
                            ],
                            className: css.priceFieldGroup
                          }),
                      props.children
                    ]
                  })
            });
}

var make = DashboardProductEditorGeneralTab;

export {
  css ,
  make ,
}
/* css Not a pure module */
